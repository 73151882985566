.pagination{
  color: #101010;
  margin: auto !important;
  

}
.pagination-session{
  width: 300px;
  margin: auto;
  margin-top: 2em;
}
.navbar-light{
  background-color: #7750f8 !important;
}
.App {
/*   text-align: center; */
/* background-color: #18191a !important; */
background: #191a1d !important;
font-family: 'Poppins', sans-serif;

height: 100%;
  margin: 0;

}
.BrowsGames{
  min-height: 800px;
  color: #FAFAFA;
}
.bg{
/*   background-image: url('./services-bg.jpg'); */
}
.LoadMorebtn{
  width: 100%;
 
}
* {
  margin: 0;
}

.mobile{
  display: none;
}
.des{
  display: block;
}
html, body {
  height: 100%;
  
}
.UserPick span{
  cursor: pointer;

}
.spmd2{
  width:20% !important;
}
.bold{
  font-weight: 900;
  color: #3687cf;
}
.FirstSessionCubeimg{
  width: 100%;
 /*  height: 250px;
  object-fit: cover; */
  position: relative;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
/*   height: 300px; */
height: 257px;
  object-fit: cover;
}
.NewSession{
  color: #FAFAFA;
}
.FirstGameSession .row>*{
/*   padding-right: calc(var(--bs-gutter-x) * .8);
  padding-left: calc(var(--bs-gutter-x) * .8); */
}
.FirstGameSession .row>*{
  padding-right: calc(var(--bs-gutter-x) * .5) !important;
  padding-left: calc(var(--bs-gutter-x) * .5) !important;
}
.FirstSessionPrice{
 
  position: absolute;
  top: -49%;
  background-color: #3687cf;
  right: 0%;
  padding: 9px;
  color: white;
  font-size: 20px;
  
}
.page404{
  padding-bottom: 3em;
}

.page404 h1{
  color: #FAFAFA;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  font-weight: bold;
}

.img404{
  max-width: 700px;
  margin: auto;
  background: #101010;
  border-radius: 20px;
  
}
.img404 img{
 

}
.FirstSessionText{
background-color: #2c2e32;
text-align: left;
padding: 20px;
border-top: solid 5px #3687cf;
position: relative;
border-bottom-right-radius:5px;
border-bottom-left-radius:5px;
height: 102px;

}
.FirstSessionTitle{
  font-size: 18px;
  font-weight: 900;
  color: #FAFAFA;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;



}

.shopImgBlock{
 padding: 50px 15px 50px 10px;
 border: 1px solid transparent;
 box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
 border-color: #303030;
 border-radius: 10px;

}
.ShopCompression p{
  text-align: center;
  font-weight: 600;
  color: #797979;
}
.shopsingleimage{
  width: 90%;
  display: block;
  margin: auto;
  vertical-align: middle;
  height: auto;
  
}
.shopImgBlock{
/*   background-color: white; */
}
.singleShopPage{
  background-color: #191a1d;
  padding-top: 2em;
  color: #fafafa;
}
.AboutSession{
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}
.PageContext{
  margin-top: 2.5%;
  margin-bottom: 2.5%;

}
.ProductSingle .form-select{
  background-color: #191a1d;
  color:#f5f5f5;
  border: 1px solid #484848;
}
.Filtes .form-select{
  color: #f5f5f5 !important;
  
}
.FilterRes{
 /*  margin-top: 2em; */
  color: #ced4da;
  font-size: 18px;

}

.CenterFlex{
  justify-content:center;
}
.TextContect{
  background:#121212;
  color: #c7c7c7;
  padding-bottom: 3em;
  border-radius: 10px;

  padding-left: 45px;
  padding-right: 45px;
  padding-top: 35px;
  padding-bottom: 35px;
  text-align: left;
  line-height: 2.3;
}
.dropdown-menu a:hover {

  background-color:rgba(0, 2, 0, 0.5);

}
.dropdown-menu{
  background:#121212 !important;
  color: #9a9a9a !important;
}
.TextContect h2{
  font-weight: 600;
  font-size: 22px;
  line-height: 3.5;
}
.ShopSession h2{
  font-weight: 600;
  font-size: 28px;
  color: #c7c7c7;
  text-align: center;
  line-height: 4.5 !important;
  margin-top: 1%;

}
.AboutContect{
  background:#121212;
  color: #c7c7c7;
  padding-bottom: 3em;
  border-radius: 10px;

  padding-left: 45px;
  padding-right: 45px;
  padding-top: 35px;
  padding-bottom: 35px;
  text-align: left;
  line-height: 2.3;
}
.AboutContect h2{
  font-weight: 600;
  font-size: 22px;
  line-height: 3.5;
}

.container{
  max-width: 1440px !important;
}
.singleShopPage .container{
  min-height: calc(100vh - 186px);
 

}
.shopsingleimage{

}
.ProductSingle .container{
  min-height: 70vh;
  min-height: -webkit-calc(100vh - 186px);
  min-height: -moz-calc(100vh - 186px);
  min-height: calc(100vh - 186px);
}
footer ul{
  padding-top: 0.5em;
}
.Footer-Menu li{
  padding-top: 0.5em;
  font-size: 14px;
  color: #c2c2c2;

}
.Footer-Menu h2{

}
footer h2{
  opacity: 0.7;
  font-size: 22px;
}
footer{

  width: 100%;
  color: #FAFAFA;
  margin-top: 4em;

    
    
 
  
}
.Footer-SocialMedia > li{
  display: inline;
  padding-left: 1em;
  opacity: 0.6;

}
footer  ul {
  list-style-type: none;
 }
 .Genersh1{
   text-align: left;
   opacity: 0.7;
 }
.footerGeners li{
  padding-top: 0.5em;
  color: #5c74f4 ;
  font-weight: 600;
  opacity: 0.8;
  text-align: left;


 }
.black-Footer{
 
  min-height: 260px;
  background-color: #121212;
  text-align: center !important;
  padding-top: 20px;

}
.black-Footer span{
  text-align: center;
  color: #e5e5e5;
}
body{
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #fcfcfc;
 
}
.ProductSingle span{
  color: #c2c2c2;
  
}
.CheapestMessage span{
  
}
.MoreOffersH2{
  margin-top: 1em;
  margin-bottom: 1em;
  color: #c2c2c2;
}
.CheapestMessage{
  position: absolute;
  top:-28%;
  background: #5c74f4;
 
  left: 0; 
  right: 0; 
  width:fit-content !important;
  margin-left: auto !important; 
  margin-right: auto !important;  
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.SingleProductRow{
/*   padding-top: 0px;
  padding-bottom: 0px; */
  background: #101010;
  margin-bottom: 15px;
  border-radius: 15px;
  position: relative;
  text-align: center;
 
}
.Browserslider h1{
  text-align: center;
  vertical-align: middle;
line-height: 250px;   
color: #2b57a6;
font-weight: 900;
text-transform: capitalize; 

}

.Genrelist h1{
  font-size: 18px;
  margin-bottom: 25px;
}
.GenreGames{
  background: #141319;
  padding-top: 2em;

  padding-bottom: 2em;

}
.GenreGames .spmd2{
  margin-bottom: 3em;
}

.Genrelist{
  background-color: #121212;
  border-radius: 10px;
  padding: 37px 18px 21px 23px;
}

.Browserslider{
  background: url(circlesbk.png);
 
   min-height: 250px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
 
}

.slider{
  background: url(newslider.jpg);
  background-position: 18% 20%;
   min-height: 370px;
  position: relative;
  background-repeat: no-repeat;
 
  -webkit-mask-image:linear-gradient(to top, transparent 1%, black 25%);
}

.slider2{
  background: url(slide2.jpg);
  background-position: 18% 20%;
  /* min-height: 500px; */
  position: relative;
  background-repeat: no-repeat;
 
  
}
.ProductPic img{
  height: 415px;
  border-radius: 20px;
  display: block;
  margin-inline-start: auto;

}
.ProductTitle{
/*   background: rgba(44,46,50,.6); */
  margin-top: calc(400px - 300px);
  padding-top: 40px;
  padding-bottom: 40px;
/*   padding-left: 20px;
  padding-left: 20px; */
  border-radius: 15px;
  text-align: left;
}
.ProductTitle h3{
 color:#9a9a9a;
 line-height: 2;
 font-weight: 600;
}

.HomePrice{
 
  display: block;
  
  font-size: 16px;
  font-weight: bold !important;
 /* color: #3e7dbc; */
 color:#5c74f4; 

 margin-right: 5px;
  

}
.ProductSingle{

}
.HomeGamesRow h1{
  font-size: 19px;
  font-weight: bold;
  padding-top: 5%;
  display: block;
  color: #414040;
}
.imgcol{
  position: relative;
}
.SingleProductRow img{

}
.GameRowSearch img{
  max-width: 100% !important;
}
.GameRowSearch{
  border-bottom: 1px solid #222222;
 padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.formSearchRes{
  background-color: #121212;
  min-height: 180px;
  width: 105%;
  position: absolute;
  border-radius: 2.25rem !important;
  top: -40%;
  z-index: 7;
  left: -2%;
  padding-top: 4em;
    padding-left: 1em;
    color: #f5f5f5;
}

.ScreenShot h1{
  font-weight: bold;
  text-align: center;
  color:#f5f5f5;

}
.ScreenShot{
  margin-top: -185px;
}
.ScreenShot img{
  max-width: 100%;
  margin-bottom: 10px;
  height: 250px;
  border-radius: 10px;
  padding-bottom: 5px;


}
.SideFilter{
  background: #101010;
  margin-top: 3em;
  padding: 2em;
  border-radius: 15px;
  
  
}
.SingleProductRow span{
 
  text-align: center;


/*   border-bottom: 1px solid #fcfcfc;
 */
}
.headerProductContect{
  padding-top: 20px;
  padding-bottom: 20px;
}
.headerProductContect h5{
  color: white;
  font-weight: 300;
  font-size: 17px;
}
.headerProductContect h1{
  color: white;
  font-weight: bold;
  text-shadow: 0 1px 1px rgb(0 0 0 / 40%);
}
.headerProduct{
  height: 385px;
  background-repeat: no-repeat;
  background-position: center; /* Center the image */
  background-attachment: fixed;

  background-size: cover; /* Resize the background image to cover the entire container */
}
.insideProductHeader{
  background-color: rgba(0,0,0,.6);
  display: block;
  height: 100%;
}
.SingleTitleV2{
  font-size: 15px;
    font-weight: 500;
    color: #FAFAFA;
    margin-left: -16px;

}
.SingleProductRow::after{
  content: '';
  display: block;
  height: 1px;
  width: 89%;
  margin: 0px 0px 0px 27px;
/*   background-color: #262626; */
}
.HomeHeaderContect b{
  color: #5c74f4 !important;
  font-weight: 700;
}
.Image-Col{
  padding-left:0px !important;
}
.ProductImage{
  height: 90px;
    width: 100%;
  /*   border-radius: 10px; */
    object-fit: cover;
    margin-left: 0px;
    border-top-left-radius:10px;
    border-bottom-left-radius:10px;

}

.SingleMultiGameV2{
  margin-bottom: 0.6em;
  background: #2c2e32; 
  margin-left:0px !important;
  margin-right:0px !important;
  border-radius: 10px;
  
}
.SingleMultiGameV2:hover{
  opacity: 0.7;

}

.SingleMultiGame::after{
  content: '';
  display: block;
  height: 1px;
  width: 89%;
  margin: 0px 0px 0px 27px;
  background-color: #e9e9e9;

}
.HomePrice{
  font-weight: 700;
}
.SingleMultiGame span{

}
.ShopBrandSingleProduct picture{
  background: #363636;
  padding-top: 19px;
  padding-right: 0px;
  padding-bottom: 23px;
  padding-left: 0px;
  opacity: 0.8;
  border-radius: 5px;
}
.CenterAlign{
  align-items:center;
}
.ShopBrandSingleProduct{
  
  padding: 10px 15px;
}
.ShopBrandSingleProduct img{
  border: 1px solid #4e4e4e;
  border-radius: 5px;

}
.blackShadow{
  background-color: rgba(0,0,0,.7); 
  height: 100%;
  width: 100%;
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.CheapestOffer{
  border: 2px solid #5c74f4;

}
.insideDivs{
  background-color: rgb(19 28 43 / 70%);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
 
 

}
.navbar-brand{
  color: #2b57a6 !important;
}
.navbar-brand .logo{
  cursor: pointer;

}
.navbar-light .navbar-toggler-icon{
  background-image:none !important;
}
.bg-light{
  background-color: white !important;
}
.navbar-toggler .collapsed{
  position: relative;
}
.navbar-toggler{
  position: relative;
  display: block;

}
.navbar-toggler span::after{
  display: block !important;
    position: absolute;
    
    height: 4px !important;
    background: #fff;
    transition: all .2s ease;
    top: -10px;
    content: "";
    width: 100%;

}
textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
    border-color:#ced4da !important;
}
.navbar-brand{
  font-weight: bold;
  font-size: 40px !important;
  color: #fff !important;
}
.insideDivs{
  
  color: #fff;
  text-align: center;

}
.insideDivs h1{
  font-weight: bold;

  font-size: 40px;
  padding-top: 7%;
 /*  color:#9a9a9a !important */

}
.navbar-toggler span::before{
  display: block !important;
    position: absolute;
    
    height: 4px !important;
    background: #fff;
    transition: all .2s ease;
    bottom: -10px;
    content: "";
    width: 100%;

}

.navbar-toggler-icon::after{
  
  

}
.navbar-toggler-icon{
  
  display: block !important;
    position: absolute;
    left: 0;
    height: 4px !important;
    background: #eee;
    transition: all .2s ease;
    content: "";
    width: 100%;

}
.ImageCube img{
 height: 250px;
 min-width: 92%;
}
.HomeGamesRow{
  background-color: #eee;
  margin-top: 20px !important;
}
.HomeGamesRow{
  margin-right: 0px !important; 
  margin-left: 0px !important;
}
.GameRow{
  
}
.imgBack{
  height: 94px !important;
  width: 100% !important;
  border-right: 6px solid #116ad0;
  /* background-position: center; */ /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.ImgCol img{
  height: 94px !important;
  width: 100% !important;
  
}
.ImgCol{
  padding-left: 0px !important;
}
.ProductSingle{
  margin-top: 2%;

}
.PricesContect{
/*   background: #121212; */
  border-radius: 16px;
/*   padding-left: 30px;
  padding-right: 30px; */
  margin-top: 35px;
 /*  padding-bottom: 45px; */
  color: #FAFAFA;
}
.SearchResultsPage{
  min-height: 90vh;

}
.SearchResultsContain{
  background: #101010;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 3em;
  border-radius: 15px;
}
.SearchResultsPage .spmd2{
  margin-top: 2em;
}
.SearchResultsPage h1{
  color: #f5f5f5;
  padding-top: 3%;
  font-weight: 700;
  font-size: 28px;
  padding-bottom: 1em;

}

.SearchResultsPage h1 label{
  color: #5c74f4;
}
a {
  color: inherit !important; /* blue colors for links too */
  text-decoration: inherit !important; /* no underline */
}
.categoryIcons{
  color: #5c74f4 !important;

}
.categorytitle b{
  color: #337ab7 !important;

}
.ShowAllResults p{
  text-align: center;
  text-decoration: underline;
  line-height: 3.5;
  color: #2b57a6;
  font-size: 20px;
  
}
.ViewMore{
  padding-top: 1em;
  color: #2b57a6 !important;
  font-size: 20px;
  font-weight: 600;
}
.categorytitle{
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 1em;
  color: #f5f5f5;
  text-align: center;

}
.Form-Search input{
  z-index: 9;
}
.Form-Search{
  width: 45% !important;
  position: relative;

}
.CategorySession{
  margin-top: 5%;
  background: #121212;
  padding-top: 4%;
    padding-right: 5%;
    padding-bottom: 4%;
    padding-left: 5%;

  border-radius: 10px;
}
.SearchForm{
  width: 100% !important;
  border-radius: 2.25rem !important;
  position: relative;
  
}
.fa-search{
  color: #9a9a9a;
}
.SearchIcon{
  position: absolute;
  left: 15px;
  z-index: 99;
  top:30%;
  font-size: 20px;
  z-index: 99;
}
header .form-control{
  z-index: 98;
}
.navbar{

padding-top: 0.1rem !important;
 padding-bottom: 0.1rem !important;
 /*  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 7%); */
  
}
.navbar-light .navbar-toggler{
  border:0px !important;
}
.navbar-light .navbar-toggler{
  color: white !important;
}
header{
  box-shadow: 0 -1px #272727e3 inset;
  
}

.react-loading-skeleton{
 
}
.navbar-light .navbar-nav .nav-link{
  color: #ffffff !important;
  font-weight: 700;
  font-size: 18px;
  font-family: 'Rajdhani', sans-serif !important;
}
.headerSearch:hover{
  border:1px solid #4e5259 !important;

}
.headerSearch{
  background:#5538b5 !important;
  border:1px solid #4e5259 !important;
  width: 350px !important;
  padding: 0.7rem 2.75rem !important;
  position: relative;
  color:#9a9a9a !important;
  
}
.relative{
  position: relative;
}
.top-loading .react-loading-skeleton {
  opacity: 0.4;
  border-radius: 0px;
}
.slider img{
  width: 100%;
  height: auto;
  display: block;

}
.navbar-dark .navbar-nav .nav-link {
  color: white !important;
  font-weight: bold;
 
  
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.5rem !important;
  padding-left: 1rem !important;
}

.Navsp{
  background-color: #101010;
  border-bottom: 1px #2b57a6 solid;
  color: white !important;
}
.App-logo {
/*   height: 40vmin;
  pointer-events: none; */
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
 */
.mt2{
  margin-top: 2%;
}
.App-header {


 /*  font-size: calc(10px + 2vmin); */

}
.logo{
  height: 50px;
}

.App-link {

}
.gameType1{
  box-shadow: 0 0 10px rgb(0 0 0 / 80%);
  border: 1px solid #101010;
  
}
.gameType1Text{
  background: #222;
    border-color: #222;
    padding-bottom: 10%;
   
}
.gameType1TextTitle{
  display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500 !important;
    padding: 6% !important;
}
.Single-Gamev1{
  text-align: center;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  border-color: #e5e5e5;
  border: 1px solid #e9e2e2;
  border-radius: 2px;

}
.gameType1TextV2{
  background-color: #f5f5f5 !important;
  padding-bottom: 15px;

}
.SingleMultiGame{
  margin-bottom: 1em;
}
.gameType1TextTitlev2{
  display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500 !important;
    padding: 6% !important;
}
.gameType1GamePriceBTN{
  color: #fff !important;
  background-color: #111 !important;
  border-color: #111 !important;
  padding-bottom: 2% !important;
}
.gameType1GamePriceBTNv2{
  color: #333 !important;
  background-color: #fff !important;
  border-color: #e5e5e5 !important;
  padding-bottom: 2% !important;
}



body{
   background-color: #fafafa; 
  height: 100%;
/*   font-family: 'Open Sans', sans-serif !important;

*/
}
.ShopSession{
  width: 100%;
  
  
}
.ShopSession h1{
  font-weight: 700;
  color: #FAFAFA !important;
  font-size: 35px;
  padding-top: 2%;
  padding-bottom: 2%;
}

.ShowContect{
  background: #121212;
  border-radius: 10px;
  padding: 15px;
  
}
.ShowContect img{
  display: block;
  margin: auto;
  width: 175px;
}
.ShopSession{
 
  padding-bottom: 2%;
 

}
.CenterTextAL{
  text-align: center;
}
.LinkPage{
  min-height: 70vh;
  text-align: center;
  padding-top: 15%;
}
.LinkPage img{
  display: block;
  margin: auto;
}
.centertext{
  text-align: center;
  display: block;
  margin: auto;
  margin-top: 2em;
  width: fit-content;
  padding-left: 18px;
  padding-right: 18px;
    border: 1px solid transparent;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    border-color: #303030;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: #0000005c;
    font-weight: 500;
    color: #c52c2c !important;
}
.shopcontectBlock{
  background-color: white;
  padding: 50px 10px 50px 50px;
  border: 1px solid transparent;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  border-color: #e5e5e5;

}
.shopcontectBlock h2{
  font-weight: 800;
}
.shopcontectBlockLinks h2{
  font-weight: 800;

}

.shopcontectBlockLinks .SingleProductRow{
       background:none !important;
       border-bottom: solid 1px #191a1d;
       justify-content: center;
       align-items: center;
}
.shopcontectBlockLinks{
  background-color: #121212;
  padding: 18px 7px 2px 5px;
  border: 1px solid transparent;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  /* border-color: #e5e5e5; */
  margin-bottom: 3rem;
  color: #FAFAFA;
  font-weight: 600;
  border-radius: 10px;

}
.ProductImage{
 /*  border-right: 4px #337ab7 solid; */
}
.col-md-1{
  position: relative;

}
.Icon-p{
  position: absolute;
}
.UserPick{
  text-align: center;
  padding-top: 2rem;
  font-weight: 500;
  font-size: 25px;
  color: #9a9a9a !important;
  padding-bottom: 2rem;

}

@media only screen and (max-width: 680px) {
 .Form-Search.mobile{
   display: block;
   width: 100% !important;
 }
 .Form-Search.des{
   display: none;
 }
 .slider img{
  height: 135px;
 }
 .gameType1Text{
  font-size: 14px !important;
  padding-bottom:6%;
 }

 .gameType1GamePriceBTN{
   font-size: 14px !important;
 }
 .MoblieColSliderProducts{
  flex: 0 0 auto !important;
  width: 38% !important;
  margin: auto !important;
  padding-top: 16px;
 }
}

.form-check-label , .form-check-input{
  cursor: pointer;
}
.SliderProduct img{
  width: 100%;
  min-height: 185px;
  object-fit: cover;


}
.SliderProduct picture{
  position: relative;
  width: 100%;
  
}

.SliderProduct-price{
  position: absolute;
  color: white;
  bottom:21%;
 font-size: 13px;
 text-align: center;

  background-color: #116ad0;
  width: 100%;

}
.SliderProduct{
  cursor: pointer;
  border: 0px;
  position: relative;
}
.swiper{
  height: 250px;
}
.SliderProduct-text{
  color: white;
  text-align: center;
  background-color: #2c2e32;
  height: 50px;
  font-size: 12px;
  padding-top: 5px;
     overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}
.presentOff{
  background-color: #2ed5c4;
  border-radius: 42px;
  color: #111;
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
  position: absolute;
  padding-bottom: 10px;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 12px;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
@media only screen and (max-width: 600px) {
  .headerProduct{
    min-height: 470px;
  }
  .CheapestMessage{
    top:-52%;
  }
  .ShopBrandSingleProduct{
    padding: 0px;

  }
  .ScreenShot{
    margin-top: 0px;
  }
  .ProductTitle{
         margin-top: 10px; 
    padding-top: 0px; 
     padding-bottom: 0px;
     
  }
  .ShopBrandSingleProduct picture{

  }
  .ProductTitle h1{
    font-size: 25px;
    
  }
  .ProductTitle h3{
         line-height: 1;

  }
  .ProductPic img{
    margin: auto;
    height: 310px;
  }
  .col-6{
    width: 50% !important;
  }
  .CategorySession{
    padding-left: 0%;
    padding-right: 0%;

  }
  .slider{
   background-size: cover;
   min-height: 220px;
  }
  .UserPick{
    font-size: 16px;
  }
  .HomeHeaderContect h1{
    margin-top: 20%;
    font-size: 18px;
    opacity: 0.8;
  }
}

